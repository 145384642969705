import type { Id } from '@/types';
import { PushMessageType } from '@/lib/serviceWorker/constants';

export class CountMessagesStorage {
  private countMessages: Record<PushMessageType, Record<string | Id, number>> =
    {
      [PushMessageType.CHANNEL]: {},
      [PushMessageType.DIALOG]: {},
      [PushMessageType.PURCHASE_INBOUND_CHANNEL]: {},
      [PushMessageType.NEED_CREATED]: {},
      [PushMessageType.NEED_STATUS_CHANGED]: {}
    };

  getCountChatMessages(chatId: string): number {
    if (!chatId) {
      return 0;
    }

    if (this.countMessages[PushMessageType.DIALOG][chatId]) {
      return this.countMessages[PushMessageType.DIALOG][chatId];
    }

    if (this.countMessages[PushMessageType.CHANNEL][chatId]) {
      return this.countMessages[PushMessageType.CHANNEL][chatId];
    }

    if (this.countMessages[PushMessageType.PURCHASE_INBOUND_CHANNEL][chatId]) {
      return this.countMessages[PushMessageType.PURCHASE_INBOUND_CHANNEL][
        chatId
      ];
    }

    return 0;
  }

  getChatIdWhereMessagesCountMoreThan(value: number) {
    const result: string[] = [];

    Object.entries(this.countMessages[PushMessageType.CHANNEL]).forEach(
      ([chatId, count]) => {
        if (count > value) {
          result.push(chatId);
        }
      }
    );

    Object.entries(this.countMessages[PushMessageType.DIALOG]).forEach(
      ([chatId, count]) => {
        if (count > value) {
          result.push(chatId);
        }
      }
    );

    Object.entries(
      this.countMessages[PushMessageType.PURCHASE_INBOUND_CHANNEL]
    ).forEach(([chatId, count]) => {
      if (count > value) {
        result.push(chatId);
      }
    });

    return result;
  }

  incrementChatCounter(type: PushMessageType, chatId: string) {
    if (!chatId) {
      return;
    }

    if (this.countMessages[type][chatId]) {
      this.countMessages[type][chatId] += 1;
    } else {
      this.countMessages[type][chatId] = 1;
    }
  }

  decrementChatCounter(chatId: string) {
    if (!chatId) {
      return;
    }

    if (this.countMessages[PushMessageType.DIALOG][chatId]) {
      this.countMessages[PushMessageType.DIALOG][chatId] -= 1;
    } else if (this.countMessages[PushMessageType.CHANNEL][chatId]) {
      this.countMessages[PushMessageType.CHANNEL][chatId] -= 1;
    } else if (
      this.countMessages[PushMessageType.PURCHASE_INBOUND_CHANNEL][chatId]
    ) {
      this.countMessages[PushMessageType.PURCHASE_INBOUND_CHANNEL][chatId] -= 1;
    }
  }

  clearChatCounterById(chatId: string) {
    if (!chatId) {
      return;
    }

    if (this.countMessages[PushMessageType.DIALOG][chatId]) {
      this.countMessages[PushMessageType.DIALOG][chatId] = 0;
    } else if (this.countMessages[PushMessageType.CHANNEL][chatId]) {
      this.countMessages[PushMessageType.CHANNEL][chatId] = 0;
    } else if (
      this.countMessages[PushMessageType.PURCHASE_INBOUND_CHANNEL][chatId]
    ) {
      this.countMessages[PushMessageType.PURCHASE_INBOUND_CHANNEL][chatId] = 0;
    }
  }
}
