export enum PushMessageType {
  CHANNEL = 'channel',
  DIALOG = 'dialog',
  PURCHASE_INBOUND_CHANNEL = 'purchase_inbound_channel',
  NEED_CREATED = 'need_created',
  NEED_STATUS_CHANGED = 'need_status_changed'
}

export enum MessageType {
  SET_ACTIVE_CHAT_ID = 'SET_ACTIVE_CHAT_ID',
  CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'
}
