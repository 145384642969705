/// <reference no-default-lib="true"/>
/// <reference lib="es2020" />
/// <reference lib="WebWorker" />

import { NotificationManager } from './notificationManager';

declare const self: ServiceWorkerGlobalScope;

export class PushServiceWorker {
  private notificationManager = NotificationManager;

  constructor() {
    self.addEventListener('install', (event) => this.installHandler(event));
    self.addEventListener('activate', (event) => this.activateHandler(event));
    self.addEventListener('push', (event) => this.pushHandler(event));
    self.addEventListener('notificationclick', (event) =>
      this.notificationClickHandler(event)
    );
    self.addEventListener('message', (event) => this.messageHandler(event));
    self.addEventListener('pushsubscriptionchange', (event) =>
      this.subscriptionChangeHandler(event)
    );
    self.addEventListener('notificationclose', (event) =>
      this.notificationCloseHandler(event)
    );
  }

  private installHandler(event: ExtendableEvent): void {
    event.waitUntil(self.skipWaiting());
  }

  private activateHandler(event: ExtendableEvent): void {
    event.waitUntil(self.clients.claim());
  }

  private async pushHandler(event: PushEvent): Promise<void> {
    try {
      if (!event.data) {
        console.info(`Push notification data is empty`);
        return;
      }

      const notifications = await self.registration.getNotifications();

      await this.notificationManager.handleNewNotification(
        event.data.json(),
        notifications,
        {
          showNotification: (title, options) =>
            self.registration.showNotification(title, options),
          getNotifications: () => self.registration.getNotifications()
        }
      );
    } catch (error) {
      console.error(`[PushServiceWorker] error`, error);
    }
  }

  private notificationClickHandler(event: NotificationEvent): void {
    event.notification.close();

    const { data } = event.notification;

    event.waitUntil(
      self.clients.matchAll({ type: 'window' }).then((windowClients) => {
        this.notificationManager.handleNotificationClick(windowClients, data, {
          waitUntil: async (promise) => event.waitUntil(promise),
          openWindow: (url) => self.clients.openWindow(url)
        });
      })
    );
  }

  private async messageHandler(event: ExtendableMessageEvent) {
    await this.notificationManager.handleMessage(event.data, {
      getNotifications: async () => self.registration.getNotifications()
    });
  }

  private notificationCloseHandler(event: NotificationEvent): void {
    // @ts-ignore
    this.notificationManager.handleClose(event.notification);
  }

  private async subscriptionChangeHandler(event: any): Promise<void> {
    console.warn('Subscription expired');

    const expiredSubscription =
      await self.registration.pushManager.getSubscription();

    event.waitUntil(
      self.registration.pushManager
        .subscribe({ userVisibleOnly: true })
        .then((subscription) => {
          console.info('Subscribed after expiration', subscription.endpoint);
          return fetch('/api/push/renew', {
            method: 'post',
            headers: {
              'Content-type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({ expiredSubscription, subscription })
          });
        })
    );
  }
}
