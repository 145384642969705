export const getNewProcurementStageEndpoint = (procurementId: string) =>
  `/requests/${procurementId}/procurement-stage/new`;
export const getEditProcurementStageEndpoint = (
  procurementId: string,
  stageId: string
) => `/requests/${procurementId}/procurement-stage/${stageId}/edit`;
export const getProcurementStagesEndpoint = (procurementId: string) =>
  `/requests/${procurementId}/procurement-stage/all`;

export const getMyProcurementsEndpoint = () => `/requests/my`;
export const getProcurementViewConfirmationEndpoint = (procurementId: string) =>
  `/requests/my/${procurementId}/viewConfirmation`;
export const getEditProcurementEndpoint = (procurementId: string) =>
  `/requests/my/${procurementId}/edit`;
export const getProcurementHistoryEndpoint = (procurementId: string) =>
  `/requests/my/${procurementId}/audit`;

export const getNeedEndpoint = (needId: string) => `/needs/${needId}/view`;

export const getNotFoundEndpoint = () => `/not-found`;

export const getCostObjectsListEndpoint = () => `/cost-accounting/cost-objects`;
export const getCostObjectViewEndpoint = (costObjectId: string) =>
  `/cost-accounting/cost-objects/${costObjectId}`;

export const getChatEndpoint = (channelId: string) => `/chat/${channelId}`;
