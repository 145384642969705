import type { Id, Nullable } from '@/types';
import { getChatEndpoint, getNeedEndpoint } from '@/shared/api/router';
import { CountMessagesStorage } from '@/lib/serviceWorker/countMessagesStorage';
import { MessageType, PushMessageType } from './constants';

interface Client {
  url: string;
  postMessage(payload: unknown): void;
  focus: VoidFunction;
}

interface PushNotification {
  type: PushMessageType;
  title: string;
  body: string;
  icon: string;
  locale: 'ru' | 'en';
  files: any[];
  data: { channelId: string; needId: Id };
  isShowAlways?: boolean;
}

interface NeedNotification {
  type: PushMessageType.NEED_CREATED | PushMessageType.NEED_STATUS_CHANGED;
  title: string;
  body: string;
  icon?: string;
  data: {
    needId: Id;
  };
}

type WaitUntil = (promise: Promise<any>) => void;
type OpenWindow = (url: string) => Promise<Nullable<Client>>;
type ShowNotification = (
  title: string,
  options?: NotificationOptions | undefined
) => Promise<void>;
type GetNotifications = () => Promise<Notification[]>;

interface HandleNotificationClickActions {
  waitUntil: WaitUntil;
  openWindow: OpenWindow;
}

interface HandleNewNotificationActions {
  showNotification: ShowNotification;
  getNotifications: GetNotifications;
}

interface HandleMessageActions {
  getNotifications: GetNotifications;
}

interface Message {
  type: MessageType;
  chatId: string;
}

interface CloseAllNotificationsByChatIdOptions {
  resetCounter?: boolean;
}

// TODO: need refactoring you have to use strategies
class NotificationManagerClass {
  private activeChatId: Nullable<string> = null;

  private firstMessageHash: Record<string, string> = {};

  private calledOnce = false;

  private countMessages = new CountMessagesStorage();

  async handleNewNotification(
    pushNotification: PushNotification,
    notifications: Notification[],
    actions: HandleNewNotificationActions
  ) {
    switch (pushNotification.type) {
      case PushMessageType.DIALOG:
      case PushMessageType.CHANNEL:
      case PushMessageType.PURCHASE_INBOUND_CHANNEL:
        await this.handleNewChatNotification(
          pushNotification,
          notifications,
          actions
        );
        break;
      case PushMessageType.NEED_CREATED:
      case PushMessageType.NEED_STATUS_CHANGED:
        await this.handleNewNeedNotification(pushNotification, actions);
        break;
      default:
        break;
    }
  }

  async handleNewChatNotification(
    pushNotification: PushNotification,
    notifications: Notification[],
    actions: HandleNewNotificationActions
  ) {
    const { type, title, body, data, isShowAlways } = pushNotification;
    const { channelId } = data;

    this.countMessages.incrementChatCounter(type, channelId);
    const messageCount = this.countMessages.getCountChatMessages(channelId);

    if (messageCount === 1) {
      this.addOrReplaceFirstMessage(channelId, body);
    }

    if (messageCount > 3) {
      this.closeAllNotificationsByChatId(notifications, channelId, {
        resetCounter: false
      });
    }

    const newNotification = this.generateNotification(
      pushNotification,
      messageCount
    );

    const showNewPush = isShowAlways || this.activeChatId !== channelId;

    if (newNotification && showNewPush) {
      await actions.showNotification(title, newNotification);

      if (!this.calledOnce) {
        this.calledOnce = true;

        setTimeout(async () => {
          const updatedNotifications = await actions.getNotifications();

          const firstNotifications =
            this.getFirstNotificationForEachChannel(updatedNotifications);

          firstNotifications.forEach((notification) => {
            if (!notification) {
              return;
            }

            const { title: nTitle, options: nOptions } =
              this.getDataFromExistNotification(notification);

            this.closeAllNotificationsByChatId(
              updatedNotifications,
              notification.data.channelId,
              { resetCounter: false }
            );

            if (nTitle && nOptions) {
              actions.showNotification(nTitle, nOptions);
            }
          });
        }, 1000);
      }
    }
  }

  private async handleNewNeedNotification(
    pushNotification: PushNotification,
    actions: HandleNewNotificationActions
  ) {
    const newNotification = this.generateNotification(pushNotification);

    await actions.showNotification(pushNotification.title, newNotification);
  }

  private getDataFromExistNotification(notification: Notification) {
    if (Array.isArray(notification)) {
      return {};
    }

    const firstMessageText =
      this.firstMessageHash[notification.data.channelId] || '';
    const messageCount = this.countMessages.getCountChatMessages(
      notification.data.channelId
    );

    return {
      title: notification.title,
      options: {
        icon: notification.icon,
        body: `${this.getUnreadTextWithTranslation(
          messageCount,
          notification.data.locale
        )}\n${firstMessageText}`,
        data: notification.data
      }
    };
  }

  private getFirstNotificationForEachChannel(notifications: Notification[]) {
    const chatsId = this.countMessages.getChatIdWhereMessagesCountMoreThan(3);

    return chatsId.map((chatId) =>
      notifications.find(
        (notification) => notification.data.channelId === chatId
      )
    );
  }

  private generateNotification(notificationData: any, messageCount?: number) {
    if (messageCount && messageCount >= 3) {
      return this.generateUnionNotification(notificationData, messageCount);
    }

    switch (notificationData.type) {
      case PushMessageType.DIALOG:
        return this.generateDialogNotification(notificationData);
      case PushMessageType.CHANNEL:
        return this.generateChannelNotification(notificationData);
      case PushMessageType.NEED_CREATED:
      case PushMessageType.NEED_STATUS_CHANGED:
        return this.generateNeedNotification(notificationData);
      default:
        throw new Error(
          `Not found handler for notification with type=${notificationData.type}`
        );
    }
  }

  private generateUnionNotification(
    notificationData: any,
    messageCount: number
  ) {
    const {
      locale,
      data: { channelId }
    } = notificationData;

    const firstMessageText = this.firstMessageHash[channelId] || '';

    return {
      icon: notificationData.icon || this.getDefaultIcon(),
      body: `${this.getUnreadTextWithTranslation(
        messageCount,
        locale
      )}\n${firstMessageText}`,
      data: notificationData.data
    };
  }

  private generateChannelNotification(notificationData: any) {
    return {
      icon: notificationData.icon || this.getDefaultIcon(),
      body: notificationData.body,
      data: {
        ...notificationData.data,
        type: PushMessageType.CHANNEL
      }
    };
  }

  private generateDialogNotification(notificationData: any) {
    const haveFiles =
      notificationData.files && notificationData.files.length > 0;

    return {
      icon: notificationData.icon || this.getDefaultIcon(),
      body: haveFiles
        ? this.displayFileName(notificationData.files[0])
        : notificationData.body,
      data: {
        ...notificationData,
        type: PushMessageType.DIALOG
      }
    };
  }

  private generateNeedNotification(notificationData: NeedNotification) {
    return {
      icon: notificationData.icon || this.getDefaultIcon(),
      body: notificationData.body,
      data: {
        ...notificationData,
        type: notificationData.type
      }
    };
  }

  private displayFileName(file: any): string {
    return `📎 ${file.name.split('-')[0]}.${file.type}`;
  }

  private displayMessageCount(count: number) {
    return count > 99 ? '99+' : count;
  }

  private getUnreadTextWithTranslation(count: number, locale: 'ru' | 'en') {
    if (locale === 'ru') {
      return `${this.displayMessageCount(
        count - 1
      )} непрочитанных сообщений и...`;
    }

    return `${this.displayMessageCount(count - 1)} unread messages and...`;
  }

  private getDefaultIcon() {
    return `${process.env.REACT_APP_UNICAT_MESSENGER_SERVICE}/img/user.png`;
  }

  private addOrReplaceFirstMessage(channelId: string, message: string) {
    this.firstMessageHash[channelId] = message;
  }

  handleNotificationClick(
    clients: readonly Client[],
    pushNotification: PushNotification,
    actions: HandleNotificationClickActions
  ) {
    switch (pushNotification.type) {
      case PushMessageType.CHANNEL:
      case PushMessageType.DIALOG:
      case PushMessageType.PURCHASE_INBOUND_CHANNEL:
        this.handleClickByChatNotification(clients, pushNotification, actions);
        break;
      case PushMessageType.NEED_CREATED:
      case PushMessageType.NEED_STATUS_CHANGED:
        this.handleClickByNeedNotification(pushNotification, actions);
        break;
      default:
        break;
    }
  }

  async handleMessage(
    message: Message,
    actions: HandleMessageActions
  ): Promise<void> {
    switch (message.type) {
      case MessageType.SET_ACTIVE_CHAT_ID:
        this.activeChatId = message.chatId;
        break;
      case MessageType.CLEAR_NOTIFICATIONS:
        this.closeAllNotificationsByChatId(
          await actions.getNotifications(),
          message.chatId,
          { resetCounter: true }
        );

        this.firstMessageHash[message.chatId] = '';

        break;
      default:
        break;
    }
  }

  private closeAllNotificationsByChatId(
    notifications: Notification[],
    chatId: string,
    options: CloseAllNotificationsByChatIdOptions = {}
  ) {
    const { resetCounter = true } = options;

    notifications.forEach((notification) => {
      if (notification.data.channelId === chatId) {
        notification.close();

        if (resetCounter) {
          this.countMessages.clearChatCounterById(chatId);
        }
      }
    });
  }

  private handleClickByChatNotification(
    clients: readonly Client[],
    pushNotification: PushNotification,
    actions: HandleNotificationClickActions
  ) {
    const { channelId } = pushNotification.data;

    let openedMessengerClient = null;

    const url = this.getChatUrl(channelId);

    const openedClientWithChat = this.getOpenedClient(
      clients,
      channelId,
      (pUrl: string, pChannelId: string) =>
        this.isTabWithOpenChat(pUrl, pChannelId)
    );

    if (!openedClientWithChat) {
      openedMessengerClient = this.getOpenedClient(
        clients,
        channelId,
        (pUrl: string) => this.isMessengerClient(pUrl)
      );
    }

    if (openedClientWithChat) {
      openedClientWithChat.postMessage({
        type: 'open-chat-by-channel-id',
        payload: { channelId }
      });
      openedClientWithChat.focus();
    } else if (openedMessengerClient) {
      openedMessengerClient.postMessage({
        type: 'open-chat-by-channel-id',
        payload: { channelId }
      });
      openedMessengerClient.focus();
    } else if (url && !openedMessengerClient && !openedClientWithChat) {
      actions.waitUntil(actions.openWindow(url));
    }
  }

  private handleClickByNeedNotification(
    pushNotification: PushNotification,
    actions: HandleNotificationClickActions
  ) {
    const { needId } = pushNotification.data;

    const url = this.getNeedUrl(needId);
    actions.waitUntil(actions.openWindow(url));
  }

  private isTabWithOpenChat(url: string, channelId: string): boolean {
    const messengerUrl = `${process.env.REACT_APP_UNICAT_MESSENGER_SERVICE}/chat/${channelId}`;

    return messengerUrl === url;
  }

  private isOpenAnyChat(url: string): boolean {
    const messengerUrl = `${process.env.REACT_APP_UNICAT_MESSENGER_SERVICE}/chat/`;
    const urlWithoutId = url.slice(0, messengerUrl.length);

    return messengerUrl === urlWithoutId;
  }

  private isMessengerClient(url: string): boolean {
    return (
      this.isOpenAnyChat(url) ||
      url === `${process.env.REACT_APP_UNICAT_MESSENGER_SERVICE}/`
    );
  }

  private getChatUrl(channelId = '') {
    return `${process.env.REACT_APP_UNICAT_MESSENGER_SERVICE}${getChatEndpoint(
      channelId
    )}`;
  }

  private getNeedUrl(needId: Id) {
    return `${process.env.REACT_APP_UNICAT_MESSENGER_SERVICE}${getNeedEndpoint(
      needId.toString()
    )}`;
  }

  private getOpenedClient(
    clients: readonly Client[],
    url: string,
    compareFn: (...args: any[]) => boolean
  ): Client | undefined {
    return clients.find((client) => compareFn(client.url, url));
  }

  handleClose(pushNotification: PushNotification) {
    const { data } = pushNotification;

    this.countMessages.clearChatCounterById(data.channelId);
  }
}

export const NotificationManager = new NotificationManagerClass();
